export const experiences = [
  {
    job: "Senior Software Engineer",
    company: "Wellx",
    date: "Aug 2024 - present",
    img_path: "./wellx.png",
    detail_link: "",
    skills: "Ruby on Rails, React, Typescript, AWS"
  },
  {
    job: "Full Stack Developer",
    company: "erad (YC S22)",
    date: "Aug 2022 - Aug 2024",
    img_path: "./erad.png",
    detail_link: "",
    skills: "PHP Symfony, React, Typescript, Flask, AWS"
  },
  {
    job: "Backend Instructor",
    company: "MStars Academy",
    date: "Sep 2021 - May 2022",
    img_path: "./mstars.png",
    detail_link: "",
    skills: "MongoDB, Express, React, Node.js, Teaching"
  },
  {
    job: "Senior Backend Developer",
    company: "Hippocards",
    date: "Aug 2019 - Aug 2021",
    img_path: "./hippo.png",
    detail_link: "",
    skills: "PHP Laravel, Rest API, MySQL, AWS, Microsoft Azure"
  },
  {
    job: "Backend Developer",
    company: "Hippocards",
    date: "Sep 2018 - Aug 2019",
    img_path: "./hippo.png",
    detail_link: "",
    skills: "PHP Laravel, Rest API, MySQL, AWS"
  },
];
