import React from 'react';
import { Container } from 'react-bootstrap';
import Experience from './components/Experience';
import { experiences } from './data/experiences';
import { linkedin } from './App';
import DownloadCV from './components/DownloadCV';
const customLink = {
    textDecoration: 'none', 
    color: '#5043ED'
}
function Home() {
    return (
        <Container className='body_container'>
            <Container className='bio_container'>
                <div className='bc_1'>
                    <img src="/me.jpeg" alt="" className='me_img' />
                </div>
                <div className='bc_2'>
                    <div className='bc_2_1'>
                        <div>
                            <span className='customH1'>Hi, I’m Eny. 👋</span>
                        </div>
                        <div>
                            <p className='description'>I’m <span className='d_color'>Software Engineer & Solutions Architect</span> based in Dubai, UAE. Currently building @Wellx</p>
                        </div>
                    </div>
                    <div className='bc_2_2'>
                        <a href='#about_me' className='d_button no-deco-link'>About me</a>
                        <a href={linkedin} target='_blank' rel="noreferrer" className='d_button no-deco-link'>Contact me</a>
                        <DownloadCV />
                    </div>
                </div>
            </Container>
            <Container className='experience_container'>
                <div><h1 className='customH2'>My experience</h1></div>
                {/* Experience component */}
                {experiences.map((experience) => <Experience skills={experience.skills} job={experience.job} company={experience.company} date={experience.date} img_path={experience.img_path} detail_link={experience.detail_link} />)}
            </Container>
            <Container className='me_container' id='about_me'>
                <div className='mc_1'>
                    <div><span className='customH2'>About me</span></div>
                    <div className='me_description'>
                        <p>I'm a software engineer from Mongolia 🇲🇳 with 7 years of experience building various startup projects. While I have full-stack expertise, I get excited about AWS solution architecture, API optimization, and real-time transactions rather than pixel-perfect design.
                        </p>
                        <p>Currently, I am working with  <a href='https://wellxai.com/' target='blank' style={customLink}> Wellx </a>, a Dubai-based health tech startup, where I'm building a platform that helps people stay healthy.</p>
  
                        <p>I spent the last two years at <a href='https://www.erad.co/' target='blank' style={customLink}>erad (YC S22)</a>, a fintech startup, where I successfully developed major features including a Loan Management System, Underwriting & Scoring System, and a Python-based multi-bank statement converter.</p>
                        <p>I love trees, mountains, and clear blue skies - they just make me happy. I'm more of a tea person 🍵 and have an athletic side that enjoys playing tennis 🎾. My go-to racket is the Wilson Blade 💚.</p>
                       
                    </div>
                </div>
                <div className='mc_2'>
                    <div>
                        <img src='/randoms/DSCF2599.webp' alt='my_random_pic' className='tea-img-custom' />
                    </div>
                    <div className='mc_2_inner'>
                        <div className='mc_2_inner_div'>    
                            <img src='/randoms/DSCF3048.webp' alt='my_random_pic' className='tea-img' />
                        </div>
                        <div className='mc_2_inner_div'>
                            <img src='/randoms/racket.png' alt='my_random_pic' className='tea-img' />
                        </div>
                    </div>
                    <div className='mc_2_inner_div'>
                        <img src='/randoms/tennis_eny.png' alt='my_random_pic' className='tea-img-custom-1' />
                        <div className='tennis-text'>
                            <span className='tennis-quote'>I left this grid empty for a reason. I'm not sure what it is yet.</span>
                            </div>
                        </div>
                </div>
            </Container>
        </Container>
    );
}

export default Home;
